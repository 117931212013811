import React from 'react';

const IconXing = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="250 250 450 450">
    <title>Xing</title>
    <path d="M 666 178C 666 178 760 178 760 178C 765 178 770 180 772 184C 775 188 774 193 772 199C 772 199 567 562 567 562C 567 562 567 563 567 563C 567 563 697 802 697 802C 700 807 700 812 698 816C 695 820 691 822 685 822C 685 822 593 822 593 822C 578 822 571 813 567 804C 567 804 435 563 434 562C 441 551 641 196 641 196C 646 187 652 178 666 178C 666 178 666 178 666 178M 369 305C 383 305 390 314 394 323C 394 323 458 433 458 434C 454 440 358 611 358 611C 353 620 347 629 333 629C 333 629 240 629 240 629C 235 629 230 626 228 623C 225 619 225 614 228 608C 228 608 327 434 327 434C 327 434 327 434 327 434C 327 434 264 325 264 325C 261 320 261 315 263 311C 265 307 270 305 276 305C 276 305 369 305 369 305" />
  </svg>
);

export default IconXing;
