import React from 'react';

import logo from './sascha_logo_v3.png';

const IconLogo = () => (
  <img
    src={logo}
    alt="sascha logo"
    height="19"
    width="42"
    style={{ transform: `scale(1.7)`, marginTop: `10px`, marginLeft: `2px` }}
  />
);

export default IconLogo;
